@import "~@ng-select/ng-select/themes/default.theme.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~ng-pick-datetime/assets/style/picker.min.css";

app-root {
  height: 100%;
  width: 75%;
}
.mat-form-field-infix{
  border:  none!important;
}
.mat-form-field-underline{
  display: none!important;
}
.icon-bar {
  position: fixed;
  top: 25%;
  right: 3%;
}
.centered{
  text-align: center!important;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 35px;
  height: 35px;
  padding: 6px 0px;
  border-radius: 20px;
  text-align: center;
  color: #212529;
  font-size: 12px;
  line-height: 1.42857;
  box-shadow: 1px 1px 0px;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
}
html, body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.rightClick-menu {
  background-color: #fafafa;
  padding: 4pt;
  font-size: 10pt;
  z-index: 1000;
  box-shadow: 0 0 12pt rgba(0, 0, 0, 0.25);
  border-radius: 4pt;
  padding: 0.5em 0 0.5em 0;
  animation: fadeIn 0.1s ease-out;
  opacity:1.0;
  display:block;
}


.rightClick-menu hr {
  border: none;
  border-bottom: 1px solid #eee;
}

.rightClick-menu div {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #333;
  padding: 0.5em 2em 0.5em 0.75em;
  max-width: 18em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightClick-menu div:hover {
  background-color: #333;
  color: #fff;
}


.rightClick-menu div::before {
  content: '';
  float: left;
  margin-right: 0.75em;
  width: 0.5em;
  height: 1em;
  display: inline-block;
}

input:invalid+span:after {
  content: '✖';
  padding-left: 5px;
}

input:valid+span:after {
  content: '✓';
  padding-left: 5px;
}


/* Animatinons */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1.0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1.0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1.0;
  }
  to {
    opacity: 0.0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1.0;
  }
  to {
    opacity: 0.0;
  }
}

.is-fadingIn {
  -webkit-animation: fadeIn 0.1s ease-out;
  animation: fadeIn 0.1s ease-out;
  opacity: 1.0;
  display: block;
}

.is-fadingOut {
  -webkit-animation: fadeOut 0.1s ease-out;
  animation: fadeOut 0.1s ease-out;
  opacity: 0.0;
  display: block;
}
.modal-dialog
{
  margin-top: 0px;
  margin-bottom: 0px;
}
.buttons{
  justify-content: flex-end;
  display: flex;
  margin: 0;

  >div:hover{
    cursor: pointer;
  }
}
.table td
{
  vertical-align: middle !important;
}

table {
  width: 100%;
}

tr.example-detail-row {
  height: -0px !important;
}

//tr.example-element-row:not(.example-expanded-row):hover {
//  background: #ffffaa;
//}

.btn-primary {
    color: #fff;
    font-size: 0.9em;
    font-weight: bold !important;
    background-color: #2242ca  ;
    border-color: #007bff;
}

tr.example-element-row:hover td {
  background: #2242ca  ;
  color: #ffffff;
    font-weight: bold !important;
}


//tr.example-element-row:active {
//  background: #2242ca;
//}

.example-element-row td {
  border-bottom-width: 0!important;
}

.example-element-detail {
  overflow: hidden;
  display: flex;
}

.example-element-descriptions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.example-element-description {
  padding: 16px;
  width: 100%;
}

.example-element-description__cell {
  padding-left: 8px;
  padding-right: 8px;
}

.example-element-description__header {
  color:#777;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.example-element-description__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-header-cell, .example-element-description__header .example-element-description__cell{
  font-weight: bold !important;
  font-size: 16px;
  color: #2242ca !important;
}

.mat-header-cell {
font-size: 16px !important;
}

.avatar {
  vertical-align: top;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.logo {
  vertical-align: top;
  width: 12%;
  height: 12%;
  border-radius: 500%;
}

.ok {
  vertical-align: center;
  width: 30%;
}

.logo2 {
  height: 35px;
}

fieldset legend{
  font-weight: bold !important;
  color: #2242ca !important;
}
.forminput-invalid {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.border {
    border: 1px solid #dee2e6 !important;
    // background-color: #EFEFEF;
    border-color: #2242ca;
}
.ng-select.ng-select-disabled>.ng-select-container {
  background-color: #e9ecef;
}

